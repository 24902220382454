import { createRouter,createWebHistory } from "vue-router";
const router = createRouter({
    history:createWebHistory(),
    routes:[
        {
            path:'/',
			component: () => import('../pages/index/index.vue'),
			redirect:'/home',
			children:[
				{
				    path:'/home',
					component: () => import('../pages/home/index.vue'),
				},
				{
				    path:'/yf',
					component: () => import('../pages/product/yf/index.vue'),
				},
				{
				    path:'/sw',
					component: () => import('../pages/product/sw/index.vue'),
				},
				{
				    path:'/ah',
					component: () => import('../pages/product/ah/index.vue'),
				},
				{
				    path:'/technology',
					component: () => import('../pages/technology/index.vue'),
				},
				{
				    path:'/aboutMe',
					component: () => import('../pages/aboutMe/index.vue'),
				},
				{
				    path:'/joinUs',
					component: () => import('../pages/joinUs/index.vue'),
				}
			]
        }
    ]
})
router.afterEach((to,from,next) => {
	$('#router-content').scrollTop(0);
 })
export default router;